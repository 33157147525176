import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
  },
  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/main.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/menu.vue')
  },
  {
    path: '/affiliate',
    name: "affiliate",
    component: () => import(/* webpackChunkName: "affiliate" */ '../views/affiliate.vue'),
  },
  {
    path: "/game",
    name: "game",
    component: () => import(/* webpackChunkName: "game" */ '../views/game.vue'),
  },
  {
    path: "/emergency",
    name: "emergency",
    component: () => import(/* webpackChunkName: "emergency" */ '../views/emergency.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
